// Things related to Autodesk Construction Cloud
const ACC = {};

ACC.currentlySelectedNode = null;

ACC.getCurrentUrlWithoutPars = function() { return window.location.origin + window.location.pathname };

// Sends the user to the Autodesk sign in page
ACC.loginToAutodesk = async function() {
	let clientId = null;
	try {
		const clidRes = await AccServices.getClientId();
		clientId = clidRes.clientId;
	} catch (error) {
		console.error("Failed to get client ID.", error);
	}

	if (!clientId) {
		console.error("Could not get client ID.");
		showErrorMessage("Failed to get needed data for redirecting to Autodesk login page.");
		return;
	}

	try {
		const url = 'https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code' +
		'&client_id=' + clientId + '&redirect_uri=' + ACC.getCurrentUrlWithoutPars() + '&scope=data:read';
		location.href = url;
	} catch (error) {
		let msg = "Failed to redirect to the Autdesk login page."
		console.error("Failed to redirect to the Autdesk login page.", error);
		showErrorMessage(msg);
	}
};

// Updates some messages on the page
ACC.updateMessages = function(progressing, userMessage, isError, allowHTML) {
	let progress = getel('accProgressNotificationDiv');
	let accNotificationDiv = getel('accNotificationDiv');
	let accNotificationBody = getel('accNotificationBody');
	if (progressing) {
		makeElementHidden(accNotificationDiv);
		makeElementVisible(progress);
	}
	else makeElementHidden(progress);
	if (isError) showErrorMessage(userMessage);
	else if (!progressing) {
		if (allowHTML) accNotificationBody.innerHTML = userMessage;
		else accNotificationBody.textContent = userMessage;
		makeElementVisible(accNotificationDiv);
	}
};

ACC.shareModelWithGroup = async function() {
	const generalErrorMsg = 'An error occurred while sharing the model.';
	try {
		ACC.updateMessages(true, '', false, false);
		const rvtFileMsg = "Please select a .rvt file in the file browser, in order to share!";
		if (ACC.currentlySelectedNode === null) {
			console.log('Nothing selected!');
			ACC.updateMessages(false, rvtFileMsg, true, false);
			return;
		}
		const encrkey = retrieveEncryptionKey();
		let accessToken = await ACC.retrieveAccessToken();
		let original = ACC.currentlySelectedNode.original;
		if (original.itemtype !== 'items') {
			console.log('Selected item is a non item, such as a folder, project or hub!');
			ACC.updateMessages(false, rvtFileMsg, true, false);
			return;
		}
		let filename = original.text;
		console.log(filename);
		if (!filename.endsWith(".rvt")) {
			console.log("File " + filename + " is not a revit file!");
			ACC.updateMessages(false, rvtFileMsg, true, false);
			return;
		}
		console.log("File " + filename + " is a revit file!");
		let itemId = original.id;
		let projectId = original.projectId;
		let itemtip = await AccServices.getItemTip(accessToken, projectId, itemId);
		console.log('itemtip');
		console.log(itemtip);
		let downloadUrn = null;
		if (itemtip && itemtip.data) {
			downloadUrn = itemtip.data.relationships && itemtip.data.relationships.storage &&
			itemtip.data.relationships.storage.meta && itemtip.data.relationships.storage.meta.link ?
			itemtip.data.relationships.storage.meta.link.href : null;
		}
		if (!downloadUrn) {
			console.error("Could not get item tip data!");
			ACC.updateMessages(false, "Failed to get item data from Autodesk!", true, false);
			return;
		}
		console.log(downloadUrn);
		// Maybe get user info everytime you access the page
		// and store it in a variable, instead of getting
		// it from APS everytime it is needed.
		let userInfo = await AccServices.getUserInfo(accessToken);
		let userId = userInfo.eidm_guid;
		console.log('got user id');
		console.log(userId);
		let res = await AccServices.startRevitExport(userId, accessToken, filename, downloadUrn);
		console.log('called startRevitExport');
		console.log(res);
		const jobId = res.id;
		console.log('Design automation job id: ' + jobId);
		const entryHash = CryptoUtil.createRandomHash();
		console.log('Index file entry hash: ' + entryHash);
		// Add entry to the index file
		var entry = Cloud.createAndInitializeIndexFileEntry();
		entry.ACC_AutomationJob = {};
		entry.ACC_AutomationJob.JobId = jobId;
		let indexRes = await Cloud.addToIndex(entryHash, entry, encrkey);
		console.log(indexRes);
		let accFinishMessage = getel('accFinishMessage');
		if (indexRes.success) ACC.updateMessages(false, accFinishMessage.innerHTML, false, true);
		else ACC.updateMessages(false, generalErrorMsg, true, false);
	} catch (error) {
        console.error("Error sharing model: ", error);
        ACC.updateMessages(false, generalErrorMsg, true, false);
	}
};

ACC.updateUI = async function() {
	let loginSection = getel('ACCLoginSection');
	let accMainMessagePanel = getel('accMainMessagePanel');
	let treeview = getel('treediv');
	let ACCLogedInSection = getel('ACCLogedInSection');
	treeview.innerHTML = '';
	let mainMessage = getel('accMainMessage');
	mainMessage.innerHTML = '';
	let accessToken = await ACC.retrieveAccessToken();
	if (accessToken) {
		let signedInMessage = getel('accSingnedInMainMessage');
		mainMessage.innerHTML = signedInMessage.innerHTML;
		makeElementVisible(accMainMessagePanel);
		makeElementHidden(loginSection);
		ACC.ShowUserInfo(accessToken);
		ACC.createTree();
		makeElementVisible(ACCLogedInSection);
	} else {
		makeElementHidden(ACCLogedInSection);
		let progress = getel('accProgressNotificationDiv');
		makeElementHidden(progress);
		let startMessage = getel('accStartMainMessage');
		mainMessage.innerHTML = startMessage.innerHTML;
		makeElementVisible(accMainMessagePanel);
		makeElementVisible(loginSection);
	}
};

ACC.logOut = async function() {
	await logOutOfACC();
	ACC.updateUI();
};

ACC.ShowUserInfo = async function(accessToken) {
	let userNameDiv = getel('userNameDiv');
	try {
		let userInfo = await AccServices.getUserInfo(accessToken);
		userNameDiv.textContent = userInfo.name;
	} catch (error) {
		console.error('Failed to get user info: ', error);
		showErrorMessage('A failure occured when trying to get user info.');
	}
};

ACC.openModelInViewer = async function(projectId, itemId) {
	let tokenData = await ACC.retrieveAccessTokenData();
	if (!tokenData) {
		console.error("Could not get access token from local storage!");
		//TODO show error message in UI
		return;
	}
	let accessToken = tokenData.access_token;
	let expires_in = tokenData.expires_in;
	let itemtip = await AccServices.getItemTip(accessToken, projectId, itemId);
	const viewerUrn = (itemtip.data.relationships != null && itemtip.data.relationships.derivatives != null ? itemtip.data.relationships.derivatives.data.id : null);

	var options = {
		env: 'AutodeskProduction',
		getAccessToken: function(onTokenReady) {
			onTokenReady(accessToken, expires_in);
		},
		api: 'derivativeV2' + (atob(viewerUrn.replace('_', '/')).indexOf('emea') > -1 ? '_EU' : '') // handle BIM 360 US and EU regions
	};
	
	Autodesk.Viewing.Initializer(options, () => {
		viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById('forgeViewer'));
		viewer.start();
		var documentId = 'urn:' + viewerUrn;
		Autodesk.Viewing.Document.load(documentId,
			function (doc) {
				const defaultModel = doc.getRoot().getDefaultGeometry();
				if (defaultModel) {
					viewer.loadDocumentNode(doc, defaultModel).then(i => {
						// Hide the toolbar after viewer is loaded
						viewer.toolbar.setVisible(false);
					});
				}
				else console.error("No default geometry found.");
			},
			function (viewerErrorCode) {
				console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode);
			});
		viewer.resize();
	});
};

ACC.createTree = function() {

	var treeview = $('#treediv').jstree({
		'core': {
			'themes': { "icons": true },
			'multiple': false,
			'data': async function (node, callback) {

				let accessToken = await ACC.retrieveAccessToken();

			   	let res = null;
			   	let itemtype = '';
			   	let hubId = '';
			   	let projectId = '';
			   	if (node.original) {
					let original = node.original;
					itemtype =  original.itemtype ? original.itemtype : '';
					hubId = original.hubId ? original.hubId : '';
					projectId = original.projectId ? original.projectId : '';
			   	}

				if (itemtype === 'hubs') {
					res = await AccServices.getProjects(accessToken, node.id);
					hubId = node.id;
				} else if (itemtype === 'projects') {
					res = await AccServices.getFolders(accessToken, hubId, node.id);
					projectId = node.id;
				} else if (itemtype === 'folders') {
					res = await AccServices.getFolderContent(accessToken, projectId, node.id);
					console.log('got folder contents');
					console.log(res);
				}
				else if (itemtype !== 'items'){
					res = await AccServices.getHubs(accessToken);
				}

				let nodeDatas = [];

				let i = 0;
				let n = res.data.length;
				for (i = 0; i < n; i++) {
					let childNodeData = res.data[i];

					let nodeData = {
						'id' : '',
						'hubId' : hubId,
						'projectId' : projectId,
						'text' : '',
						'itemtype' : '',
						'icon' : '',
						'state' : {
							'opened' : false,
							'selected' : false
					  	},
					  	'children' : true
				   	}
				   	nodeData.id = childNodeData.id;
				   	nodeData.itemtype = childNodeData.type;
				   	nodeData.text = childNodeData.attributes.name;
				   	if (nodeData.itemtype === 'items') {
						nodeData.text = childNodeData.attributes.displayName;
						nodeData.children = false;
				   	}
					if (nodeData.itemtype === 'items')
						nodeData.icon = nodeData.text.endsWith('.rvt') ? Icons.rvtFileIcon : Icons.generalFileIcon;
					else if (nodeData.itemtype === 'folders') nodeData.icon = Icons.folderIcon;
					else if (nodeData.itemtype === 'hubs') nodeData.icon = Icons.hubIcon;
					else if (nodeData.itemtype === 'projects') nodeData.icon = Icons.projectIcon;
				   	nodeDatas.push(nodeData);
				}
				callback.call(this, nodeDatas);
			}
		},
		"sort": function (a, b) {
			var a1 = this.get_node(a);
			var b1 = this.get_node(b);
			return a1.text > b1.text ? 1 : -1; // basic name/text sort
		},
		"plugins": ["sort"]
	}).bind("activate_node.jstree", function (evt, data) {
		console.log('activate_node.jstree');
	});

	treeview.on("load_node.jstree", function (node, status) {
		if (!status.status) {
			// TODO put something else here than an alert message
			alert('TreeNodeLoadFailure');
		}
	});

	treeview.on("select_node.jstree", function (evt, data) {
		console.log('Node selected');
		let revitFileSelected = false;
		let node = data.node;
		ACC.currentlySelectedNode = node;
		let nodeOriginal = node.original;
		if (data.node && (nodeOriginal.itemtype === 'hubs' || nodeOriginal.itemtype === 'projects' ||
			nodeOriginal.itemtype === 'folders')) {
			try { treeview.jstree("open_node", node); }
			catch (error) {
				console.error('Failed to open tree view node: ', error);
				showErrorMessage('A failure occured when trying to expand an item in the file browser.');
			}
		} else {
			let filename = nodeOriginal.text;
			revitFileSelected = filename.endsWith(".rvt");
			try { ACC.openModelInViewer(nodeOriginal.projectId, nodeOriginal.id); }
			catch (error) {
				const modelErrorMsg = 'A failure occured when trying to open model in viewer';
				console.error(modelErrorMsg + ': ', error);
				showErrorMessage(modelErrorMsg + '.');
			}
		}

		// Here, if revitFileSelected then show button else hide it
		let uploadButton = getel('shareACCModelWithGroupButton');
		if (revitFileSelected) makeElementVisible(uploadButton);
		else makeElementHidden(uploadButton);
	});
};

// Get authorization code from URL and then remove it from the URL
ACC.getCodeAndRemoveFromUrl = function() {
    const url = new URL(window.location);
    const code = url.searchParams.get('code');
    if (code) {
        url.searchParams.delete('code');
        window.history.replaceState({}, document.title, url.toString());
    }
    return code;
};

// Get access token data from local storage
ACC.retrieveAccessTokenData = async function() {
	let accessTokenData = AccUtil.retrieveAccessTokenDataFromLocalStorage();
	if (accessTokenData) {
		// See if it's too old
		let ageInSeconds = (Date.now() - accessTokenData.milliseconds_since_epoch) / 1000;
		// Token expiry buffer in secconds
		// If it's shorter than this until a token expires we try refreshing it
		const TOKEN_EXPIRY_BUFFER = 600;
		if (ageInSeconds + TOKEN_EXPIRY_BUFFER > accessTokenData.expires_in) {
			// Get new token using a refresh token
			// Each refresh token can only be used once.
			// Calling this will make the current access token and refresh token obsolete
			try {
				//TODO look a bit more into how this works.
				// Like is it possible that the regeneration will fail and also that the current
				// token becomes invalid.
				const newTokenData = await AccServices.regenerateAccessToken(accessTokenData.refresh_token);
				newTokenData.milliseconds_since_epoch = Date.now(); // Time in milliseconds since the Unix epoch
				console.log('Regenerated access token');
				//console.log(newTokenData);
				// Important to store the newly generated token data
				AccUtil.storeAccessTokenData(newTokenData);
				return newTokenData;
			} catch (error) {
				console.error('Failed refreshing access token: ', error);
				if (ageInSeconds > accessTokenData.expires_in) {
					console.error('Token expired');
					showErrorMessage(
						'There was an authentication failure. If issues occur, please check your network connection or try signing in to Autodesk Construction Cloud later.');
					return null;
				}
			}
		}
		// In case the current token is not expired or about to expire
		// or there was a failure to refresh the token we just
		// return the current token
		return accessTokenData;
	}
	return null;
};

// Get access token from local storage
ACC.retrieveAccessToken = async function () {
	let tokenData = await ACC.retrieveAccessTokenData();
	if (tokenData) return tokenData.access_token;
	else return null;
};

// Get access token data from Autodesk in exchange for an authorization code
ACC.getAccessTokenFromAPS = async function (code)
{
	const redirectUri = ACC.getCurrentUrlWithoutPars();
	const res = await AccServices.request3LeggedAccessToken(code, redirectUri);
	res.milliseconds_since_epoch = Date.now(); // Time in milliseconds since the Unix epoch
	console.log('Got access token: ', res);
	return res;
};

ACC.bodyOnLoad = async function()
{
	let linked = isLinked();
	if (!linked)
	{
		// Redirect to manage group page if not connected to group
		goToGroupPage();
	}

	addGroupIdToTabLinksIfNeeded();
	
	let tokenData = null;
	// Try getting the authorization code from Autodesk.
	// This should be in the URL when going back to the callback
	// uri after a user has logged into their Autodesk account.
	let code = ACC.getCodeAndRemoveFromUrl();
	if (code) {
		tokenData = await ACC.getAccessTokenFromAPS(code);
		if (tokenData !== null) AccUtil.storeAccessTokenData(tokenData);
	}

	ACC.updateUI();
};
